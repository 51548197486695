<template>
    <v-dialog v-model="isOpen" width="800">
        <template v-slot:activator="{ on }">
            <div @click="isOpen = true">
                <v-select v-show="show" :value="selectedContacts" :items="selectedContactsFull" :loading="isLoading" multiple item-value="email" label="Contacts" background-color="white" hide-details readonly outlined dense v-on="on">
                    <template v-slot:selection="data">
                        <v-chip v-if="data.index === 0" small label>
                            <v-avatar class="blue white--text text-uppercase" left size="16">
                                {{ data.item.name.slice(0, 1) }}
                            </v-avatar>
                            <span v-if="data.item.name || data.item.name">
                                {{ data.item.name }} {{ data.item.firstname }}
                            </span>
                            <span v-else>
                                <i> Sans nom </i>
                            </span>
                        </v-chip>

                        <span v-if="data.index === 1" class="grey--text text-caption">
                            (+{{ selectedContacts.length - 1 }} autres)
                        </span>
                    </template>

                    <template v-slot:item="{ item }">
                        <v-list-item-avatar class="blue white--text text-uppercase headline">
                            {{ item.name.slice(0, 1) }}
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>
                                <span v-if="item.name || item.name">
                                    {{ item.name }} {{ item.firstname }}
                                </span>
                                <span v-else>
                                    <i> Sans nom </i>
                                </span>
                            </v-list-item-title>

                            <v-list-item-subtitle>
                                <span v-if="item.email">
                                    {{ item.email }}
                                </span>
                                <span v-else>
                                    <i> Sans email </i>
                                </span>
                                -
                                <span v-if="item.mobile">
                                    {{ item.mobile }}
                                </span>
                                <span v-else>
                                    <i> Sans portable </i>
                                </span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-select>
            </div>
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Selection de contacts
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <v-text-field v-model="search" label="Recherche" class="mb-4" hide-details outlined dense />

                <ProgramAutocomplete ref="programAutocomplete" class="mb-4" v-model="programId" label="Programme" noDynamicSearch />

                <v-row>
                    <v-col cols="6">
                        <div class="d-flex align-center justify-space-between">
                            <h4> Contacts séléctionnés </h4>

                            <v-spacer />

                            <v-btn v-show="selectedContactsFull.length > 0" color="primary" @click="deselectAll()" x-small> Tout Déséléctionner </v-btn> <br>
                        </div>

                        <v-list>
                            <v-list-item v-for="contact of selectedContactsFull" :key="contact.email + '_selected'" @click="removeContact(contact)">
                                <v-list-item-avatar class="white--text text-uppercase" size="25" color="blue">
                                    {{ contact.name[0] }}
                                </v-list-item-avatar>

                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="subtitle-2"> {{ contact.name }} {{ contact.firstname }} </v-list-item-title>

                                    <v-list-item-subtitle>
                                        <v-chip v-for="program of contact.programs" :key="contact.email + '_' + program.title" class="mr-1 cursor-pointer" small label>
                                            {{ program.title }} -
                                            <span v-for="product of program.products" :key="contact.email + '_' + program.title + '_' + product.reference" class="ml-1"> {{ product.reference }} - {{ product.typeLabel }} </span>
                                        </v-chip>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>

                    <v-divider vertical class="my-4" />

                    <v-col cols="6">
                        <div class="d-flex align-center justify-space-between">
                            <h4> Recherche </h4>

                            <v-spacer />

                            <v-btn v-show="searchContacts.length > 0" color="primary" @click="selectAll()" x-small> Tout séléctionner </v-btn> <br>
                        </div>

                        <v-list>
                            <v-list-item v-for="contact of searchContacts" :key="contact.email +  '_search'" @click="addContact(contact)">
                                <v-list-item-avatar class="white--text text-uppercase" size="25" color="grey">
                                    {{ contact.name[0] }}
                                </v-list-item-avatar>

                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="subtitle-2"> {{ contact.name }} {{ contact.firstname }} </v-list-item-title>

                                    <v-list-item-subtitle>
                                        <v-chip v-for="program of contact.programs" :key="contact.email + '_' + program.title" class="mr-1 cursor-pointer" x-small label>
                                            {{ program.title }} -
                                            <span v-for="product of program.products" :key="contact.email + '_' + program.title + '_' + product.reference" class="ml-1"> {{ product.reference }} - {{ product.typeLabel }} </span>
                                        </v-chip>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small>
                    Valider
                </v-btn>

                <v-spacer />

                <v-btn @click="cancel()" small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ProgramAutocomplete from '../widgets/ProgramAutocomplete.vue';

export default {
    name: 'ContactAutocomplete',

    components: {
        ProgramAutocomplete
    },

    props: {
        value: {},
        show: { type: Boolean, default: true },
        returnSaleId: { type: Boolean, default: false }
    },

    data: () => ({
        isOpen: false,
        selectedContacts: [],
        selectedContactsFull: [],

        programId: null,
        programsFetched: false,

        searchContacts: [],
        isLoading: false,
        search: null,
        searchTimeout: null,

        initSelectedContacts: [],
        initSelectedContactsFull: []
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                if (!this.programsFetched) {
                    this.programsFetched = true;
                    this.$nextTick(() => {
                        this.$refs.programAutocomplete.fetchPrograms({ limit: 1000 });
                    });   
                }
            }
        },

        search() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof this.search !== 'string' || this.search.length.length < 3) {
                    return;
                }
                this.makeSearch();
            }, 500);
        },

        programId() {
            this.makeSearch();
        }
    },

    methods: {
        submit() {
            this.isOpen = false;
            if (this.returnSaleId) {
                this.$emit('input', this.selectedContactsFull.map((c) => c.saleId));
            } else {
                this.$emit('input', this.selectedContacts);
            }
        },

        async init() {
            if ((Array.isArray(this.selectedContacts) && this.selectedContacts.length > 0) || typeof this.selectedContacts === 'number') {
                const contacts = Array.isArray(this.selectedContacts) ? this.selectedContacts : [this.selectedContacts];
                this.selectedContactsFull = await this.fetchContacts({ emails: contacts });
                this.initSelectedContactsFull = JSON.parse(JSON.stringify(this.selectedContactsFull));
                this.initSelectedContacts = JSON.parse(JSON.stringify(this.selectedContacts));
            }
        },

        addContact(contact) {
            const existing = this.selectedContactsFull.find((c) => c.email === contact.email);
            if (existing) {
                return;
            }
            this.selectedContactsFull.push(contact);
            this.selectedContacts.push(contact.email);
        },

        removeContact(contact) {
            const index = this.selectedContactsFull.findIndex((c) => c.email === contact.email);
            this.selectedContactsFull.splice(index, 1);

            const index2 = this.selectedContacts.findIndex((email) => email === contact.email);
            this.selectedContacts.splice(index2, 1);
        },

        selectAll() {
            for (const contact of this.searchContacts) {
                this.addContact(contact);
            }
        },

        deselectAll() {
            this.selectedContactsFull.splice(0, this.selectedContactsFull.length);
            this.selectedContacts.splice(0, this.selectedContacts.length);
        },

        cancel() {
            this.selectedContacts = this.initSelectedContacts;
            this.selectedContactsFull = this.initSelectedContactsFull;
            this.isOpen = false;
        },

        async makeSearch() {
            if (this.isLoading) {
                return;
            }

            if (!this.search && !this.programId) {
                this.searchContacts = [];
                return;
            }

            const query = { limit: 5 };

            if (this.search) {
                query.quickSearch = this.search;
            }

            if (this.programId) {
                query.limit = 10000;
                query.programs = this.programId;
            }

            this.setLoading(true);
            this.searchContacts = await this.fetchContacts(query);
            this.setLoading(false);
        },

        async fetchContacts(query) {
            try {
                this.isLoading = true;
                const { contacts } = await this.repos.contacts.getContacts(query);
                return contacts;
            } catch (err) {
                console.error(err);
                return [];
            } finally {
                this.isLoading = false;
            }
        }
    },

    created() {
        if (this.value) {
            this.selectedContacts = JSON.parse(JSON.stringify(this.value));
        }

        this.init();
    }
};
</script>